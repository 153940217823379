.proposal{
    display: flex;
    flex-direction: row;
}
.text-box {
    max-width: 50%;
    color: #440d56;
}

h1 {
    font-size: 10rem;
    color: #ad48ce;
}

p {
    font-size: 2rem;
    
}

.slot-image {
    width: 500px;
}

@media (max-width: 768px) {
    h1 {
        font-size: 4rem;
    }

    p {
        font-size: 1.4rem;
    }

    .text-box {
        max-width: 90%;
    }

    .proposal{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .slot-image {
        width: 250px;
    }
}
