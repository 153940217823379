#dexscreener-embed {
    position: relative;
    width: 100%;
}



#dexscreener-embed iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
}