* {
  user-select: none;
  -webkit-user-drag: none;
}

.croki-gif {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 5%;
  bottom: -20%;
  width: auto;
  height: 80%;

}

.buy-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 100%;
}

.title {
  font-size: 16rem;
  max-width: 100%;
  color: #c44bf6;
  margin: 10px 0;
  text-shadow: -4px 4px 0px white;
  -webkit-text-stroke: 2px #440d56 ;
  position: relative;

}

.butterfly-image {
  position: absolute;
  top: 0px;
  right: -40px;
  width: 125px;
  height: auto;
}

.buy-now {
  background-color: #c44bf6;
  color: white;
  height: 100px;
  border: none;
  padding: 15px 150px;
  font-size: 2rem;
  cursor: pointer;
  border-radius: 25px;
  margin-top: 00px;
  border: solid black 8px;
  font-family: 'every';
  box-shadow: -4px 4px 0px white;
}

.buy-now:hover {
  background-color: #440d56;
}

.social-icons {
  margin-top: 20px;
}

.footer-text {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  color: #c44bf6;
  font-size: 1.1rem;
  font-family: 'every';
}

.grass-right {
  position: absolute;
  bottom: 0;
  left: -10%;
  width: 50%;
  height: auto;
}

.grass-left {
  position: absolute;
  bottom: 0;
  right: -10%;
  width: 50%;
  height: auto;
}

.grass {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  
  height: auto;
}

.grassBack {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 200%;
  height: auto;
}

.grass1 {
  position: absolute;
  transform: scaleY(-1);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: auto;
}

.grassBack1 {
  position: absolute;
  transform: scaleY(-1);
  top: 0;
  left: 0;
  z-index: 0;
  width: 130%;
  height: auto;
}
.social-icons{
  z-index: 20;
}
@media (max-width: 768px) {

  .gif-container {
    display: none;
  }

  .title {
    font-size: 8rem;
  }

  .butterfly-image {
    width: 30px;
    top: 20px;
    right: 0px;
  }

  .buy-now {
    padding: 10px 20px;
    font-size: 2rem;

  }

  .social-icons .icon {
    font-size: 2rem;
    margin: 0 10px;
  }

  .footer-text {
    display: none;
  }

  .croki-gif {
    display: none;
  }

}

@media (min-width: 769px) and (max-width: 1200px) {
  .croki-gif {
    display: none;
  }
}
