html {
  font-size: 16px;
}

.App {
  font-family: 'every', sans-serif;
  background-color: #f0f0f0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  max-width: 100vw;
}

@font-face {
  font-family: 'every';
  src: url('./components/fonts/poetsenone-regular-webfont.woff2') format('woff2'),
    url('./components/fonts/poetsenone-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.main-page {
  width: 100%;
  height: 100%;
}

.banner {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: solid    12px #440d56;
  overflow: hidden;
}

.buy-now-banner {
  background-color: #79bf57;
}

.supply-banner {
  background-color: #79bf57;
}

.community-banner {
  background-color: #c44bf6;
}

.proposal-banner {
  background-color: #c44bf6;
}

.how-buy-banner {
  background-color: #79bf57;
}

.distribution-banner {
  background-color: #c44bf6;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.max-width {
  max-width: 1920px;
}

.d-flex-row {
  display: flex;
  flex-direction: row;
}

.d-flex-row-r {
  display: flex;
  flex-direction: row-reverse;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.d-flex-col-r {
  display: flex;
  flex-direction: column-reverse;
}


.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gif-container,
.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40%;
  padding: 20px;
}

.front {
  z-index: 1;
}

.text-center{
  text-align: center;
}
.canvasjs-chart-credit{
  display: none !important;
}
.text-none{
  text-decoration: none;
  width:auto;
}