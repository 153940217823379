h1 {
    font-size: 14rem;
    max-width: 100%;
    color: #c44bf6;
    margin: 0px 0;
    text-shadow: -4px 4px 0px white;
    -webkit-text-stroke: 2px #440d56;
    position: relative;

}

.coin-image {
    width: 400px;
}

span {
    letter-spacing: 15px;
}

h2 {
    font-size: 5rem;
    max-width: 100%;
    color: white;
    margin-top: -40px;
    margin: -40px 0 0 0;
    text-shadow: -2px 2px 0px white;
    -webkit-text-stroke: 2px #440d56;
    position: relative;

}

li {
    font-size: 3rem;
    text-shadow: -3px 3px 0px rgba(0, 0, 0, 0.399);
    color: #69aa4a;
    -webkit-text-stroke: 2px #000000;
    user-select: none;
    -webkit-user-drag: none;
}

li::marker {
    color: #69aa4a;
    -webkit-text-stroke: 2px #000000;
    box-shadow: -2px 2px 0px rgba(0, 0, 0, 0.399);
    content: '●';
    font-size: 1.5em;
    margin-right: 0.5em;
}

li::marker:before {
    position: absolute;
    left: 0;
    content: '';
    border: 2px solid #000000;
    border-radius: 100%;
    width: 1em;
    height: 1em;
    display: inline-block;
    padding: 0.25em;
    line-height: 1.0;
    margin-right: 0.5em;
}

@media (max-width: 768px) {

    h1 {
        font-size: 8rem;
        line-height: 190%;
    }

    ul {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        margin: 0;
        padding: 10px;
        list-style: none;

    }

    ul li {
        font-size: 1.8rem;
        list-style-type: none;
        list-style: none;
    }

    .coin-image {
        width: 250px;
        margin-top: 20px;
    }

    li::marker:before {
        display: none;
    }
}