.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  z-index: 100000;

}

.icon {
  font-size: 4rem;
  color: #2e7137;
  margin: 0 30px;
  cursor: pointer;
  z-index: 100000;
}

.icon:hover {
  color: #c44bf6;
}

@media (max-width: 768px) {
  .icon {
    font-size: 1.5rem;
    margin: 0 5px;
  }
}